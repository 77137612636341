import { useCommonTranslation } from "components/translate";
import React from "react";
import { Typewriter } from "react-simple-typewriter";

const TypeWriterWrapper = React.memo(
  ({ words, ...rest }: { words: string[] }) => {
    const { t } = useCommonTranslation();
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
      setLoaded(true);
    }, []);
    if (!loaded) {
      return <span>{t(words[0])}</span>;
    }
    return (
      <Typewriter
        words={words.map((word) => t(word))}
        loop={false}
        cursor
        cursorStyle="."
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}
        {...rest}
      />
    );
  }
);

export default TypeWriterWrapper;
