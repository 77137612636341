import type { EnrichedTweet } from '../utils'
import { TweetInfoCreatedAt } from './tweet-info-created-at'
import s from './tweet-info.module.css'
import React from "react";

export const TweetInfo = ({ tweet }: { tweet: EnrichedTweet }) => (
  <div className={s.info}>
    <TweetInfoCreatedAt tweet={tweet} />
    <a
      className={s.infoLink}
      href="https://help.twitter.com/en/twitter-for-websites-ads-info-and-privacy"
      target="_blank"
      rel="noopener noreferrer nofollow"
      aria-label="Twitter for Websites, Ads Information and Privacy"
    >
      <svg viewBox="0 0 24 24" aria-hidden="true" className={s.infoIcon}>
        <g>
          <path d="M13.5 8.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5S11.17 7 12 7s1.5.67 1.5 1.5zM13 17v-5h-2v5h2zm-1 5.25c5.66 0 10.25-4.59 10.25-10.25S17.66 1.75 12 1.75 1.75 6.34 1.75 12 6.34 22.25 12 22.25zM20.25 12c0 4.56-3.69 8.25-8.25 8.25S3.75 16.56 3.75 12 7.44 3.75 12 3.75s8.25 3.69 8.25 8.25z"></path>
        </g>
      </svg>
    </a>
  </div>
)
