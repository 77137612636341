const otherTestimonialData = [
  {
    content:
      "Last week, I drafted an article using Junia.ai. Post a minor fact-checking and editing, I was astounded to find my article on Google's first page by Sunday.",
    reviewName: "Alex Thompson",
    authorImage: "/images/avatars/Alexa Thompson.png",
    type: "email",
    date: "2023-07-10T08:22:24-07:00",
  },
  {
    content:
      "Junia.ai offers a mind-blowing change to the blogging game. The content I could generate was far superior than other free tools out there.",
    reviewName: "Isabella Taylor",
    type: "email",
    date: "2023-09-10T12:18:22-00:00",
  },
  {
    content:
      "Junia.ai took me by surprise with its speed and accuracy. What I appreciate most is how swiftly it generates pillar content, with an intuitive workflow. Excited for what's to come.",
    reviewName: "Laura Parker",
    type: "interview",
    date: "2023-09-22T07:11:12-00:00",
  },
  {
    content:
      "Junia.ai is the best AI software I've ever used for creating high-quality, realistic answers with links unlike Writesonic.com.",
    reviewName: "Noah Scott",
    type: "interview",
    date: "2023-09-27T10:13:18-06:00",
  },
  {
    content:
      "I'm incredibly impressed by what Junia.ai does. We've been using it to create top-notch, SEO-friendly blog content. Can't imagine how we'd operate without it.",
    reviewName: "Rachel Adams",
    authorImage: "/images/avatars/Rachel Adams.png",
    type: "interview",
    date: "2023-07-27T09:10:10-00:00",
  },
  {
    content:
      "Since implementing Junia.ai, our sales have increased by 7%. It's a powerful tool that has greatly benefited our business.",
    reviewName: "Ava Richards",
    type: "email",
    date: "2023-08-22T13:10:00-00:00",
  },
  {
    content:
      "With Junia.ai, we managed to outline content for a new section on our website within days instead of weeks. It's the most intuitive AI copywriter tool I've used.",
    reviewName: "Mia Harris",
    authorImage: "/images/avatars/Mia Harris.png",
    type: "survey",
    date: "2023-09-03T16:45:00-00:00",
  },
  {
    content:
      "I have been using Junia.ai since it launched, and it has been great – reliable, good features, responsive support, and regular product updates.",
    reviewName: "Mason Davis",
    authorImage: "/images/avatars/Mason Davis.png",
    type: "interview",
    date: "2023-09-03T13:15:00-00:00",
  },
  {
    content:
      "Junia's workflow for auto long-form blog post creation is incredibly efficient. It has not only saved us time but also increased our productivity.",
    reviewName: "Emily Bennett",
    type: "survey",
    date: "2023-09-18T15:05:00-00:00",
  },
  {
    content:
      "Junia.ai makes it easy for me to write efficiently for SEO traffic. It helped find quality phrases and keywords that have high-traffic content.",
    reviewName: "Ava Simmons",
    authorImage: "/images/avatars/Ava Simmons.png",
    type: "survey",
    date: "2023-09-18T17:00:00-00:00",
  },
].map((item) => {
  return {
    ...item,
    noOrg: true,
  };
});

export const videoReviewData = [
  {
    link: "/materials/junia-ai-review-alex-maximo.mp4",
    name: "Alex Maximo",
    imageUrl: "/materials/junia-ai-review-alex-maximo-thumbnail.png",
    label: "Editor | AI Fosshub",
    uploadDate: "2023-10-05T00:00:00+00:00",
    duration: "PT122S",
    videoDescription:"A review of Junia AI, #1 content creation tool for SEO by Alex Maximo, Editor of https://ai.fosshub.com/",
    videoTitle:"Junia Review: Create Long-Form Content for Better SEO"
  },
  {
    embedLink: `https://www.youtube-nocookie.com/embed/O1Z0U0Yx5PA?si=UuJ9OlBHWWo0MI32`,
    title:
      "[The best AI for e-book publishing] I tried using Junia.ai (Junior AI)!",
    name: "kindle出版ホシヒデch",
  },
  {
    embedLink:
      "https://www.youtube-nocookie.com/embed/J6yk0Edf76I?si=X3ji8OeyT5H7CGIO",
    title: "Create SEO-Optimized Content with Junia: AI SEO Tool Tutorial",
    name: "Baveling",
  },
  {
    title:
      "This is how to use the popular AI tool Junior! How to write 6000 characters at once",
    embedLink: `https://www.youtube-nocookie.com/embed/x_tL3hhVmBg?si=1kJfsgbnYEjMgkfR`,
    name: "Game Strategy Channel by Jimba GO",
  },
  {
    title:
      "Junia AI Review: The Ultimate AI Writing Tool for SEO-Rich Blog Posts | AffordHunt",
    embedLink:
      "https://www.youtube-nocookie.com/embed/mtcjfOGKTt8?si=8NRd0-fYS3FUkOjY",
    name: "AffordHunt",
  },
  {
    embedLink: `https://www.youtube-nocookie.com/embed/88y8CrqzAaY?si=l58CxnOYGhX7YPxk`,
    title: "Best Artificial Intelligence Blogging App",
    name: "Yapay Zeka Günlükleri",
  },
];

export const tweetsData = [
  {
    __typename: "Tweet",
    lang: "en",
    type: "tweet",
    favorite_count: 0,
    possibly_sensitive: false,
    created_at: "2023-05-11T12:36:09.000Z",
    display_text_range: [0, 242],
    entities: {
      hashtags: [],
      urls: [
        {
          display_url: "junia.ai",
          expanded_url: "http://junia.ai",
          indices: [38, 61],
          url: "https://t.co/4MYYcx20z2",
        },
      ],
      user_mentions: [],
      symbols: [],
    },
    id_str: "1656639328813023232",
    bookmarks: 0,
    views: "6",
    text: "I recently had the opportunity to use https://t.co/4MYYcx20z2, and I have to say that I am thoroughly impressed. This innovative platform is a game-changer for anyone looking to automate their business processes and streamline their workflow.",
    user: {
      id_str: "1656276163679985664",
      name: "Elisa Breno",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1656276496527380481/LnInzhVs_normal.png",
      screen_name: "ElisaBreno66711",
      verified: false,
      is_blue_verified: false,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1656639328813023232"],
      editable_until_msecs: "1683810369000",
      is_edit_eligible: true,
      edits_remaining: "5",
    },
    conversation_count: 0,
    news_action_type: "conversation",
    card: {
      card_platform: {
        platform: {
          audience: {
            name: "production",
          },
          device: {
            name: "Swift",
            version: "12",
          },
        },
      },
      name: "summary_large_image",
      url: "https://t.co/4MYYcx20z2",
      binding_values: {
        photo_image_full_size_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 419,
            width: 800,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=800x419",
          },
          type: "IMAGE",
        },
        thumbnail_image: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 196,
            width: 400,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=400x400",
          },
          type: "IMAGE",
        },
        description: {
          string_value:
            "Junia is the best AI writer that helps you create high-quality and original content for blogs, emails, and ads with AI images in seconds",
          type: "STRING",
        },
        domain: {
          string_value: "www.junia.ai",
          type: "STRING",
        },
        thumbnail_image_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 293,
            width: 600,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=600x600",
          },
          type: "IMAGE",
        },
        summary_photo_image_small: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 202,
            width: 386,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=386x202",
          },
          type: "IMAGE",
        },
        thumbnail_image_original: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=orig",
          },
          type: "IMAGE",
        },
        site: {
          scribe_key: "publisher_id",
          type: "USER",
          user_value: {
            id_str: "1640059359806857218",
            path: [],
          },
        },
        photo_image_full_size_small: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 202,
            width: 386,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=386x202",
          },
          type: "IMAGE",
        },
        summary_photo_image_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 419,
            width: 800,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=800x419",
          },
          type: "IMAGE",
        },
        thumbnail_image_small: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 70,
            width: 144,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=144x144",
          },
          type: "IMAGE",
        },
        creator: {
          type: "USER",
          user_value: {
            id_str: "1640059359806857218",
            path: [],
          },
        },
        thumbnail_image_x_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=png&name=2048x2048_2_exp",
          },
          type: "IMAGE",
        },
        photo_image_full_size_original: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=orig",
          },
          type: "IMAGE",
        },
        photo_image_full_size_alt_text: {
          string_value: "Junia AI - AI powered content writer",
          type: "STRING",
        },
        vanity_url: {
          scribe_key: "vanity_url",
          string_value: "junia.ai",
          type: "STRING",
        },
        photo_image_full_size: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 314,
            width: 600,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=600x314",
          },
          type: "IMAGE",
        },
        summary_photo_image_alt_text: {
          string_value: "Junia AI - AI powered content writer",
          type: "STRING",
        },
        thumbnail_image_color: {
          image_color_value: {
            palette: [
              {
                rgb: {
                  blue: 95,
                  green: 25,
                  red: 61,
                },
                percentage: 52.78,
              },
              {
                rgb: {
                  blue: 244,
                  green: 241,
                  red: 243,
                },
                percentage: 30.89,
              },
              {
                rgb: {
                  blue: 90,
                  green: 45,
                  red: 101,
                },
                percentage: 7.75,
              },
              {
                rgb: {
                  blue: 55,
                  green: 13,
                  red: 30,
                },
                percentage: 3.72,
              },
              {
                rgb: {
                  blue: 97,
                  green: 58,
                  red: 133,
                },
                percentage: 0.87,
              },
            ],
          },
          type: "IMAGE_COLOR",
        },
        title: {
          string_value:
            "Junia - Write Better with AI | Best Content Creation Platform",
          type: "STRING",
        },
        summary_photo_image_color: {
          image_color_value: {
            palette: [
              {
                rgb: {
                  blue: 95,
                  green: 25,
                  red: 61,
                },
                percentage: 52.78,
              },
              {
                rgb: {
                  blue: 244,
                  green: 241,
                  red: 243,
                },
                percentage: 30.89,
              },
              {
                rgb: {
                  blue: 90,
                  green: 45,
                  red: 101,
                },
                percentage: 7.75,
              },
              {
                rgb: {
                  blue: 55,
                  green: 13,
                  red: 30,
                },
                percentage: 3.72,
              },
              {
                rgb: {
                  blue: 97,
                  green: 58,
                  red: 133,
                },
                percentage: 0.87,
              },
            ],
          },
          type: "IMAGE_COLOR",
        },
        summary_photo_image_x_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=png&name=2048x2048_2_exp",
          },
          type: "IMAGE",
        },
        summary_photo_image: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 314,
            width: 600,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=600x314",
          },
          type: "IMAGE",
        },
        photo_image_full_size_color: {
          image_color_value: {
            palette: [
              {
                rgb: {
                  blue: 95,
                  green: 25,
                  red: 61,
                },
                percentage: 52.78,
              },
              {
                rgb: {
                  blue: 244,
                  green: 241,
                  red: 243,
                },
                percentage: 30.89,
              },
              {
                rgb: {
                  blue: 90,
                  green: 45,
                  red: 101,
                },
                percentage: 7.75,
              },
              {
                rgb: {
                  blue: 55,
                  green: 13,
                  red: 30,
                },
                percentage: 3.72,
              },
              {
                rgb: {
                  blue: 97,
                  green: 58,
                  red: 133,
                },
                percentage: 0.87,
              },
            ],
          },
          type: "IMAGE_COLOR",
        },
        photo_image_full_size_x_large: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=png&name=2048x2048_2_exp",
          },
          type: "IMAGE",
        },
        card_url: {
          scribe_key: "card_url",
          string_value: "https://t.co/4MYYcx20z2",
          type: "STRING",
        },
        summary_photo_image_original: {
          image_value: {
            alt: "Junia AI - AI powered content writer",
            height: 600,
            width: 1227,
            url: "https://pbs.twimg.com/card_img/1678055590906478592/YVjTLQRv?format=jpg&name=orig",
          },
          type: "IMAGE",
        },
      },
    },
    isEdited: false,
    isStaleEdit: false,
  },
  otherTestimonialData[4],

  {
    __typename: "Tweet",
    type: "tweet",

    in_reply_to_screen_name: "Ratanchahar_",
    in_reply_to_status_id_str: "1673015687462387712",
    in_reply_to_user_id_str: "1500471475685380099",
    lang: "en",
    favorite_count: 0,
    created_at: "2023-06-26T10:20:12.000Z",
    display_text_range: [14, 290],
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [
        {
          id_str: "1500471475685380099",
          indices: [0, 13],
          name: "Ratan chahar⚡",
          screen_name: "Ratanchahar_",
        },
      ],
      symbols: [],
    },
    id_str: "1673274957344784384",
    bookmarks: "0",
    views: 40,
    text: "@Ratanchahar_ This is mind-blowing! AI technology is taking content creation to the next level. Thanks for sharing this incredible tool. With AI-powered writing, creating SEO-friendly blog posts has never been easier. Just answer a few simple questions, enter your keywords, and let the AI…",
    user: {
      id_str: "4472745568",
      name: "Andrii Kladchenko",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/676100398005006336/LFLo_ZkK_normal.jpg",
      screen_name: "AndriiKlad",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1673274957344784384"],
      editable_until_msecs: "1687778412000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    conversation_count: 0,
    news_action_type: "conversation",
    parent: {
      lang: "en",
      reply_count: 63,
      retweet_count: 192,
      favorite_count: 1171,
      possibly_sensitive: false,
      created_at: "2023-06-25T17:09:57.000Z",
      display_text_range: [0, 193],
      entities: {
        hashtags: [],
        urls: [],
        user_mentions: [],
        symbols: [],
        media: [
          {
            display_url: "pic.twitter.com/i1qnWRW57z",
            expanded_url:
              "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
            indices: [194, 217],
            url: "https://t.co/i1qnWRW57z",
          },
        ],
      },
      id_str: "1673015687462387712",
      text: "This blog was generated entirely using AI.\n\nAnd it ranked at the top of google overnight.\n\nHere's How to Write an SEO-powered Blog using AI in just 10 minutes (Undetectable and Plagiarism-Free) https://t.co/i1qnWRW57z",
      user: {
        id_str: "1500471475685380099",
        name: "Ratan chahar⚡",
        profile_image_url_https:
          "https://pbs.twimg.com/profile_images/1663861470030069760/o_zOxACq_normal.jpg",
        screen_name: "Ratanchahar_",
        verified: false,
        is_blue_verified: true,
        profile_image_shape: "Circle",
      },
      edit_control: {
        edit_tweet_ids: ["1673015687462387712"],
        editable_until_msecs: "1687716597000",
        is_edit_eligible: false,
        edits_remaining: "5",
      },
      mediaDetails: [
        {
          display_url: "pic.twitter.com/i1qnWRW57z",
          expanded_url:
            "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
          ext_media_availability: {
            status: "Available",
          },
          indices: [194, 217],
          media_url_https: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
          original_info: {
            height: 423,
            width: 1073,
            focus_rects: [
              {
                x: 0,
                y: 0,
                w: 755,
                h: 423,
              },
              {
                x: 84,
                y: 0,
                w: 423,
                h: 423,
              },
              {
                x: 110,
                y: 0,
                w: 371,
                h: 423,
              },
              {
                x: 189,
                y: 0,
                w: 212,
                h: 423,
              },
              {
                x: 0,
                y: 0,
                w: 1073,
                h: 423,
              },
            ],
          },
          sizes: {
            large: {
              h: 423,
              resize: "fit",
              w: 1073,
            },
            medium: {
              h: 423,
              resize: "fit",
              w: 1073,
            },
            small: {
              h: 268,
              resize: "fit",
              w: 680,
            },
            thumb: {
              h: 150,
              resize: "crop",
              w: 150,
            },
          },
          type: "photo",
          url: "https://t.co/i1qnWRW57z",
        },
      ],
      photos: [
        {
          backgroundColor: {
            red: 204,
            green: 214,
            blue: 221,
          },
          cropCandidates: [
            {
              x: 0,
              y: 0,
              w: 755,
              h: 423,
            },
            {
              x: 84,
              y: 0,
              w: 423,
              h: 423,
            },
            {
              x: 110,
              y: 0,
              w: 371,
              h: 423,
            },
            {
              x: 189,
              y: 0,
              w: 212,
              h: 423,
            },
            {
              x: 0,
              y: 0,
              w: 1073,
              h: 423,
            },
          ],
          expandedUrl:
            "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
          url: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
          width: 1073,
          height: 423,
        },
      ],
      isEdited: false,
      isStaleEdit: false,
    },
    isEdited: false,
    isStaleEdit: false,
    note_tweet: {
      id: "Tm90ZVR3ZWV0UmVzdWx0czoxNjczMjc0OTU3MjY1MDk2NzA3",
    },
  },
  otherTestimonialData[7],
  {
    __typename: "Tweet",
    lang: "en",
    favorite_count: 625,
    type: "tweet",

    possibly_sensitive: false,
    created_at: "2023-07-12T17:02:16.000Z",
    display_text_range: [0, 188],
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [],
      symbols: [],
      media: [
        {
          display_url: "pic.twitter.com/ebxpWzE3q3",
          expanded_url:
            "https://twitter.com/Ratanchahar_/status/1679174347301453825/photo/1",
          indices: [189, 212],
          url: "https://t.co/ebxpWzE3q3",
        },
      ],
    },
    id_str: "1679174347301453825",
    views: "246.8k",
    bookmarks: "1,624",
    text: "These blogs are 100% AI-generated.\n\nAnd they ranked at the top of google overnight.\n\nHere's How to Write an SEO-powered Blog using AI in just 10 minutes (Undetectable and Plagiarism-Free): https://t.co/ebxpWzE3q3",
    user: {
      id_str: "1500471475685380099",
      name: "Ratan chahar⚡",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1663861470030069760/o_zOxACq_normal.jpg",
      screen_name: "Ratanchahar_",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1679174347301453825"],
      editable_until_msecs: "1689184936000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    mediaDetails: [
      {
        display_url: "pic.twitter.com/ebxpWzE3q3",
        expanded_url:
          "https://twitter.com/Ratanchahar_/status/1679174347301453825/photo/1",
        ext_media_availability: {
          status: "Available",
        },
        indices: [189, 212],
        media_url_https: "https://pbs.twimg.com/media/F02ZdIJagAYiagt.jpg",
        original_info: {
          height: 1342,
          width: 1549,
          focus_rects: [
            {
              x: 0,
              y: 0,
              w: 1549,
              h: 867,
            },
            {
              x: 0,
              y: 0,
              w: 1342,
              h: 1342,
            },
            {
              x: 0,
              y: 0,
              w: 1177,
              h: 1342,
            },
            {
              x: 0,
              y: 0,
              w: 671,
              h: 1342,
            },
            {
              x: 0,
              y: 0,
              w: 1549,
              h: 1342,
            },
          ],
        },
        sizes: {
          large: {
            h: 1342,
            resize: "fit",
            w: 1549,
          },
          medium: {
            h: 1040,
            resize: "fit",
            w: 1200,
          },
          small: {
            h: 589,
            resize: "fit",
            w: 680,
          },
          thumb: {
            h: 150,
            resize: "crop",
            w: 150,
          },
        },
        type: "photo",
        url: "https://t.co/ebxpWzE3q3",
      },
    ],
    photos: [
      {
        backgroundColor: {
          red: 204,
          green: 214,
          blue: 221,
        },
        cropCandidates: [
          {
            x: 0,
            y: 0,
            w: 1549,
            h: 867,
          },
          {
            x: 0,
            y: 0,
            w: 1342,
            h: 1342,
          },
          {
            x: 0,
            y: 0,
            w: 1177,
            h: 1342,
          },
          {
            x: 0,
            y: 0,
            w: 671,
            h: 1342,
          },
          {
            x: 0,
            y: 0,
            w: 1549,
            h: 1342,
          },
        ],
        expandedUrl:
          "https://twitter.com/Ratanchahar_/status/1679174347301453825/photo/1",
        url: "https://pbs.twimg.com/media/F02ZdIJagAYiagt.jpg",
        width: 1549,
        height: 1342,
      },
    ],
    conversation_count: 56,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  otherTestimonialData[8],
  otherTestimonialData[9],
  {
    __typename: "Tweet",
    lang: "en",
    type: "tweet",
    bookmarks: "0",
    views: "31",
    favorite_count: 1,
    possibly_sensitive: false,
    created_at: "2023-07-12T19:46:07.000Z",
    display_text_range: [0, 158],
    entities: {
      hashtags: [],
      urls: [
        {
          display_url: "junia.ai",
          expanded_url: "http://junia.ai",
          indices: [0, 23],
          url: "https://t.co/D1q2PiePc3",
        },
      ],
      user_mentions: [],
      symbols: [],
    },
    id_str: "1679215579184472064",
    text: "https://t.co/D1q2PiePc3\nIt can create SEO friendly blogposts in seconds.\nYou can give some simple details of what you want and it is there for you.Impressive.",
    user: {
      id_str: "1543330363103215616",
      name: "Mrs Khan",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1678483187700310016/dEIM-z1O_normal.jpg",
      screen_name: "MrsSZAKhan",
      verified: false,
      is_blue_verified: false,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1679215579184472064"],
      editable_until_msecs: "1689194767000",
      is_edit_eligible: true,
      edits_remaining: "5",
    },
    conversation_count: 0,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  otherTestimonialData[5],
  otherTestimonialData[6],

  {
    __typename: "Tweet",
    type: "tweet",

    in_reply_to_screen_name: "Ratanchahar_",
    in_reply_to_status_id_str: "1673015687462387712",
    in_reply_to_user_id_str: "1500471475685380099",
    lang: "en",
    favorite_count: 0,
    created_at: "2023-06-26T00:14:55.000Z",
    display_text_range: [25, 103],
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [
        {
          id_str: "1500471475685380099",
          indices: [0, 13],
          name: "Ratan chahar⚡",
          screen_name: "Ratanchahar_",
        },
        {
          id_str: "1583314884015828992",
          indices: [14, 24],
          name: "Sutra | AI",
          screen_name: "CopySutra",
        },
      ],
      symbols: [],
    },
    id_str: "1673122633368457220",
    bookmarks: 0,
    views: "84",
    text: "@Ratanchahar_ @CopySutra SEO agencies should hang it up. Or at least start looking for differentiators.",
    user: {
      id_str: "1629319620405473280",
      name: "Jerem.ai💡",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1634616134887505920/Fk4YIqjy_normal.jpg",
      screen_name: "aitod_ai",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1673122633368457220"],
      editable_until_msecs: "1687742095000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    conversation_count: 0,
    news_action_type: "conversation",
    parent: {
      lang: "en",
      reply_count: 63,
      retweet_count: 192,
      favorite_count: 1171,
      possibly_sensitive: false,
      created_at: "2023-06-25T17:09:57.000Z",
      display_text_range: [0, 193],
      entities: {
        hashtags: [],
        urls: [],
        user_mentions: [],
        symbols: [],
        media: [
          {
            display_url: "pic.twitter.com/i1qnWRW57z",
            expanded_url:
              "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
            indices: [194, 217],
            url: "https://t.co/i1qnWRW57z",
          },
        ],
      },
      id_str: "1673015687462387712",
      text: "This blog was generated entirely using AI.\n\nAnd it ranked at the top of google overnight.\n\nHere's How to Write an SEO-powered Blog using AI in just 10 minutes (Undetectable and Plagiarism-Free) https://t.co/i1qnWRW57z",
      user: {
        id_str: "1500471475685380099",
        name: "Ratan chahar⚡",
        profile_image_url_https:
          "https://pbs.twimg.com/profile_images/1663861470030069760/o_zOxACq_normal.jpg",
        screen_name: "Ratanchahar_",
        verified: false,
        is_blue_verified: true,
        profile_image_shape: "Circle",
      },
      edit_control: {
        edit_tweet_ids: ["1673015687462387712"],
        editable_until_msecs: "1687716597000",
        is_edit_eligible: false,
        edits_remaining: "5",
      },
      mediaDetails: [
        {
          display_url: "pic.twitter.com/i1qnWRW57z",
          expanded_url:
            "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
          ext_media_availability: {
            status: "Available",
          },
          indices: [194, 217],
          media_url_https: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
          original_info: {
            height: 423,
            width: 1073,
            focus_rects: [
              {
                x: 0,
                y: 0,
                w: 755,
                h: 423,
              },
              {
                x: 84,
                y: 0,
                w: 423,
                h: 423,
              },
              {
                x: 110,
                y: 0,
                w: 371,
                h: 423,
              },
              {
                x: 189,
                y: 0,
                w: 212,
                h: 423,
              },
              {
                x: 0,
                y: 0,
                w: 1073,
                h: 423,
              },
            ],
          },
          sizes: {
            large: {
              h: 423,
              resize: "fit",
              w: 1073,
            },
            medium: {
              h: 423,
              resize: "fit",
              w: 1073,
            },
            small: {
              h: 268,
              resize: "fit",
              w: 680,
            },
            thumb: {
              h: 150,
              resize: "crop",
              w: 150,
            },
          },
          type: "photo",
          url: "https://t.co/i1qnWRW57z",
        },
      ],
      photos: [
        {
          backgroundColor: {
            red: 204,
            green: 214,
            blue: 221,
          },
          cropCandidates: [
            {
              x: 0,
              y: 0,
              w: 755,
              h: 423,
            },
            {
              x: 84,
              y: 0,
              w: 423,
              h: 423,
            },
            {
              x: 110,
              y: 0,
              w: 371,
              h: 423,
            },
            {
              x: 189,
              y: 0,
              w: 212,
              h: 423,
            },
            {
              x: 0,
              y: 0,
              w: 1073,
              h: 423,
            },
          ],
          expandedUrl:
            "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
          url: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
          width: 1073,
          height: 423,
        },
      ],
      isEdited: false,
      isStaleEdit: false,
    },
    isEdited: false,
    isStaleEdit: false,
  },
];

export const testmonialData = [
  otherTestimonialData[0],
  {
    __typename: "Tweet",
    lang: "tr",
    favorite_count: 104,
    views: "26.9k",
    bookmarks: "87",
    type: "tweet",

    possibly_sensitive: false,
    created_at: "2023-10-25T11:27:35.000Z",
    display_text_range: [0, 279],
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [
        {
          id_str: "1640059359806857218",
          indices: [71, 80],
          name: "Junia AI",
          screen_name: "Junia_ai",
        },
      ],
      symbols: [],
      media: [
        {
          display_url: "pic.twitter.com/jOnOZ6mMyx",
          expanded_url:
            "https://twitter.com/YapayZekaGunluk/status/1717140847899160777/photo/1",
          indices: [279, 302],
          url: "https://t.co/jOnOZ6mMyx",
        },
      ],
    },
    id_str: "1717140847899160777",
    name: "Yapay Zeka Günlükleri",
    text: "Meet the clear leader in content creation with artificial intelligence @Junia_ai. \n\nAmong the blog writing tools I've reviewed so far, it's an application that stands out with its features.\n\nI wanted to share this with you and show how a blog post can be prepared.\n\nHere you go; https://t.co/jOnOZ6mMyx",
    user: {
      id_str: "1650603989539364893",
      name: "Yapay Zeka Günlükleri",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1660916646985322497/fubDflW-_normal.png",
      screen_name: "YapayZekaGunluk",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1717140847899160777"],
      editable_until_msecs: "1698236855000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    mediaDetails: [
      {
        display_url: "pic.twitter.com/jOnOZ6mMyx",
        expanded_url:
          "https://twitter.com/YapayZekaGunluk/status/1717140847899160777/photo/1",
        ext_media_availability: { status: "Available" },
        indices: [279, 302],
        media_url_https: "https://pbs.twimg.com/media/F9SCPxbWUAA58s8.jpg",
        original_info: {
          height: 720,
          width: 1280,
          focus_rects: [
            { x: 0, y: 0, w: 1280, h: 717 },
            { x: 440, y: 0, w: 720, h: 720 },
            { x: 484, y: 0, w: 632, h: 720 },
            { x: 620, y: 0, w: 360, h: 720 },
            { x: 0, y: 0, w: 1280, h: 720 },
          ],
        },
        sizes: {
          large: { h: 720, resize: "fit", w: 1280 },
          medium: { h: 675, resize: "fit", w: 1200 },
          small: { h: 383, resize: "fit", w: 680 },
          thumb: { h: 150, resize: "crop", w: 150 },
        },
        type: "photo",
        url: "https://t.co/jOnOZ6mMyx",
      },
    ],
    photos: [
      {
        backgroundColor: { red: 204, green: 214, blue: 221 },
        cropCandidates: [
          { x: 0, y: 0, w: 1280, h: 717 },
          { x: 440, y: 0, w: 720, h: 720 },
          { x: 484, y: 0, w: 632, h: 720 },
          { x: 620, y: 0, w: 360, h: 720 },
          { x: 0, y: 0, w: 1280, h: 720 },
        ],
        expandedUrl:
          "https://twitter.com/YapayZekaGunluk/status/1717140847899160777/photo/1",
        url: "https://pbs.twimg.com/media/F9SCPxbWUAA58s8.jpg",
        width: 1280,
        height: 720,
      },
    ],
    conversation_count: 3,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  {
    type: "tweet",
    __typename: "Tweet",
    lang: "en",
    favorite_count: 2,
    possibly_sensitive: false,
    created_at: "2023-06-24T04:00:44.000Z",
    display_text_range: [0, 248],
    views: "175",
    entities: {
      hashtags: [],
      urls: [
        {
          display_url: "Junia.ai",
          expanded_url: "http://Junia.ai",
          indices: [111, 134],
          url: "https://t.co/Wy0mv67czM",
        },
      ],
      user_mentions: [],
      symbols: [],
    },
    bookmarks: 2,
    id_str: "1672454684655616000",
    text: "Every one of the blog post I published in the last few days all rank within 5th place on Google. All thanks to https://t.co/Wy0mv67czM's blog post workflow. Here is a sneak peek of the auto linking that's coming to further boost your website's SEO:",
    user: {
      id_str: "1021276443580551168",
      name: "Yi",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1549882358781906945/fhnjQP5P_normal.jpg",
      screen_name: "yizhuang1996",
      verified: false,
      is_blue_verified: false,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1672454684655616000"],
      editable_until_msecs: "1687582844000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    conversation_count: 3,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  otherTestimonialData[1],
  {
    type: "tweet",
    __typename: "Tweet",
    lang: "en",
    favorite_count: 1171,
    possibly_sensitive: false,
    created_at: "2023-06-25T17:09:57.000Z",
    display_text_range: [0, 193],
    views: "494.6K",
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [],
      symbols: [],
      media: [
        {
          display_url: "pic.twitter.com/i1qnWRW57z",
          expanded_url:
            "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
          indices: [194, 217],
          url: "https://t.co/i1qnWRW57z",
        },
      ],
    },
    id_str: "1673015687462387712",
    bookmarks: "3,489",
    text: "This blog was generated entirely using AI.\n\nAnd it ranked at the top of google overnight.\n\nHere's How to Write an SEO-powered Blog using AI in just 10 minutes (Undetectable and Plagiarism-Free) https://t.co/i1qnWRW57z",
    user: {
      id_str: "1500471475685380099",
      name: "Ratan chahar⚡",
      profile_image_url_https:
        "https://www.junia.ai/images/o_zOxACq_normal.jpeg",
      screen_name: "Ratanchahar_",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1673015687462387712"],
      editable_until_msecs: "1687716597000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    mediaDetails: [
      {
        display_url: "pic.twitter.com/i1qnWRW57z",
        expanded_url:
          "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
        ext_media_availability: {
          status: "Available",
        },
        indices: [194, 217],
        media_url_https: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
        original_info: {
          height: 423,
          width: 1073,
          focus_rects: [
            {
              x: 0,
              y: 0,
              w: 755,
              h: 423,
            },
            {
              x: 84,
              y: 0,
              w: 423,
              h: 423,
            },
            {
              x: 110,
              y: 0,
              w: 371,
              h: 423,
            },
            {
              x: 189,
              y: 0,
              w: 212,
              h: 423,
            },
            {
              x: 0,
              y: 0,
              w: 1073,
              h: 423,
            },
          ],
        },
        sizes: {
          large: {
            h: 423,
            resize: "fit",
            w: 1073,
          },
          medium: {
            h: 423,
            resize: "fit",
            w: 1073,
          },
          small: {
            h: 268,
            resize: "fit",
            w: 680,
          },
          thumb: {
            h: 150,
            resize: "crop",
            w: 150,
          },
        },
        type: "photo",
        url: "https://t.co/i1qnWRW57z",
      },
    ],
    photos: [
      {
        backgroundColor: {
          red: 204,
          green: 214,
          blue: 221,
        },
        cropCandidates: [
          {
            x: 0,
            y: 0,
            w: 755,
            h: 423,
          },
          {
            x: 84,
            y: 0,
            w: 423,
            h: 423,
          },
          {
            x: 110,
            y: 0,
            w: 371,
            h: 423,
          },
          {
            x: 189,
            y: 0,
            w: 212,
            h: 423,
          },
          {
            x: 0,
            y: 0,
            w: 1073,
            h: 423,
          },
        ],
        expandedUrl:
          "https://twitter.com/Ratanchahar_/status/1673015687462387712/photo/1",
        url: "https://pbs.twimg.com/media/Fzcdz06aAAIfcy5.jpg",
        width: 1073,
        height: 423,
      },
    ],
    conversation_count: 63,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  {
    name: "Thu Nghiem",
    url: "https://www.trustpilot.com/reviews/649d7a3feee0eb1de8276394",
    authorImage:
      "https://user-images.trustpilot.com/649d7a37f3b04b0011ad2fcc/73x73.png",
    type: "trustpilot",
    reviewName: "Best AI tool for blog posts",
    date: "2023-06-29T14:34:07.000Z",
    content: `I have tried out 10 other ai platform including jasper and copyai, this is the best one so far, I am just mind blown by the AI image in the editor and seo blog post workflow`,
  },
  {
    name: "Brocke Halery",
    url: "https://trstp.lt/dNRvErmT-",
    type: "trustpilot",
    reviewName: "Recently I've been using Junia a lot",
    date: "2023-05-20T10:35:15.000Z",
    content: `Recently  I've been using Junia a lot. It has many templates, which makes it simpler to skip the trouble of creating and modifying prompts for ChatGPT. I made the switch from ChatGPT to Junia.`,
  },
  {
    __typename: "Tweet",
    type: "tweet",
    lang: "en",
    favorite_count: 232,
    possibly_sensitive: false,
    created_at: "2023-05-10T09:15:21.000Z",
    display_text_range: [0, 156],
    bookmarks: "173",
    entities: {
      hashtags: [],
      urls: [],
      user_mentions: [],
      symbols: [],
      media: [
        {
          display_url: "pic.twitter.com/80AnjUmoEs",
          expanded_url:
            "https://twitter.com/hasantoxr/status/1656226407603740674/video/1",
          indices: [157, 180],
          url: "https://t.co/80AnjUmoEs",
        },
      ],
    },
    views: "46.2k",
    id_str: "1656226407603740674",
    text: "ChatGPT is not the only powerful AI tool.\n\nIntroducing Junia AI: Generate amazing content and images instantly with this top AI tool.\n\nHere's how to use it: https://t.co/80AnjUmoEs",
    user: {
      id_str: "1506055005992026115",
      name: "Hasan Toor ✪",
      profile_image_url_https:
        "https://pbs.twimg.com/profile_images/1581916687628087296/YFTwuoXE_normal.jpg",
      screen_name: "hasantoxr",
      verified: false,
      is_blue_verified: true,
      profile_image_shape: "Circle",
    },
    edit_control: {
      edit_tweet_ids: ["1656226407603740674"],
      editable_until_msecs: "1683711921000",
      is_edit_eligible: false,
      edits_remaining: "5",
    },
    mediaDetails: [
      {
        additional_media_info: {},
        display_url: "pic.twitter.com/80AnjUmoEs",
        expanded_url:
          "https://twitter.com/hasantoxr/status/1656226407603740674/video/1",
        ext_media_availability: {
          status: "Available",
        },
        ext_media_stats: {
          view_count: 10056,
        },
        indices: [157, 180],
        media_url_https:
          "https://pbs.twimg.com/ext_tw_video_thumb/1656226179072786432/pu/img/mHe3U7sT_anJq10Y.jpg",
        original_info: {
          height: 928,
          width: 1598,
        },
        sizes: {
          large: {
            h: 928,
            resize: "fit",
            w: 1598,
          },
          medium: {
            h: 697,
            resize: "fit",
            w: 1200,
          },
          small: {
            h: 395,
            resize: "fit",
            w: 680,
          },
          thumb: {
            h: 150,
            resize: "crop",
            w: 150,
          },
        },
        type: "video",
        url: "https://t.co/80AnjUmoEs",
        video_info: {
          aspect_ratio: [799, 464],
          duration_millis: 11006,
          variants: [
            {
              bitrate: 832000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/618x360/76AnmPr7ITX-3HGG.mp4?tag=12",
            },
            {
              content_type: "application/x-mpegURL",
              url: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/pl/a9-TViFkdueyIIRS.m3u8?tag=12&container=fmp4",
            },
            {
              bitrate: 256000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/464x270/kPcOg8N-4gU7dVKJ.mp4?tag=12",
            },
            {
              bitrate: 2176000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/1238x720/Y_I3EiKvy_UD-CAh.mp4?tag=12",
            },
          ],
        },
      },
    ],
    photos: [],
    video: {
      aspectRatio: [799, 464],
      contentType: "media_entity",
      durationMs: 11006,
      mediaAvailability: {
        status: "available",
      },
      poster:
        "https://pbs.twimg.com/ext_tw_video_thumb/1656226179072786432/pu/img/mHe3U7sT_anJq10Y.jpg",
      variants: [
        {
          type: "video/mp4",
          src: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/618x360/76AnmPr7ITX-3HGG.mp4?tag=12",
        },
        {
          type: "application/x-mpegURL",
          src: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/pl/a9-TViFkdueyIIRS.m3u8?tag=12&container=fmp4",
        },
        {
          type: "video/mp4",
          src: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/464x270/kPcOg8N-4gU7dVKJ.mp4?tag=12",
        },
        {
          type: "video/mp4",
          src: "https://video.twimg.com/ext_tw_video/1656226179072786432/pu/vid/1238x720/Y_I3EiKvy_UD-CAh.mp4?tag=12",
        },
      ],
      videoId: {
        type: "tweet",
        id: "1656226407603740674",
      },
      viewCount: 10056,
    },
    conversation_count: 16,
    news_action_type: "conversation",
    isEdited: false,
    isStaleEdit: false,
  },
  otherTestimonialData[2],
  {
    name: "Darren Harvey",
    url: "https://www.trustpilot.com/reviews/6472632fa1e69f55abdd7aac",
    type: "trustpilot",
    reviewName: "Junia helped me greatly with creating…",
    date: "2023-05-27T22:08:15.000Z",
    content: `Junia helped me greatly with creating resources for my students. I can’t believe how accurate it is.`,
  },
  {
    name: "Yujing",
    authorUrl: "https://www.producthunt.com/@ue_nc",
    authorHandle: "@ue_nc",
    date: "2023-06-30T02:42:44-07:00",
    url: "https://www.producthunt.com/products/junia-ai?comment=2528110#junia-ai-2",
    authorImage:
      "https://ph-avatars.imgix.net/5778433/231dfdbb-96e8-4640-a72f-7b8bdd9ced57?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=1",
    type: "producthunt",
    data: "",
    content:
      "It is truly exciting to see Junia come alive. I used Junia a lot and it never fails to provide me with the best AI experience. It has enhanced various aspects of my life.",
  },
  otherTestimonialData[3],

  ...tweetsData,
];

const allTweetsIds = [
  "1672454684655616000",
  "1673015687462387712",
  "1656226407603740674",
  "1656639328813023232",
  "1673274957344784384",
  "1679174347301453825",
  "1679215579184472064",
  "1673122633368457220",
];
