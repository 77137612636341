import { Box } from "components/ui/box";
import React from "react";
import { ResponsiveText } from "./shared";
import { useTranslation } from "components/translate";
import { InlineSpan } from "components/as-inline-span";
import { TestmonialList } from "../testmonial-list";
import TypeWriterWrapper from "components/type-writer";

import { HighlightedCallToAction } from "../call-to-action";
import { serverRoutes } from "client-server-shared/config/routes";
import { isCn } from "client-server-shared/constants";
import MSLogo from "public/ms_badge_dark.png";
import PilotBadge from "public/trustpilot_badge.png";
import BusinessInsiderLogo from "public/insider_logo.png";
import AIForThatLogo from "public/aiforthat_logo.png";
import SaasHubLogo from "public/saashub_logo.png";
import MSNLogo from "public/msn_logo.png";
import Image from "next/image";
const commonLogoStyle = {
  width: {
    xs: "350px",
    sm: "350px",
    md: "350px",
    lg: "150px",
  },
  objectFit: "contain",
};
const hideOnMobile = {
  display: {
    xs: "none",
    sm: "none",
    md: "none",
    lg: "block",
  },
};

const Testmonial = ({ title, description, inReviewPage, titleProps = {} }) => {
  const { t } = useTranslation("front-page");
  return (
    <Box component="section">
      <Box
        sx={{
          marginBottom: "48px",
        }}
      >
        <ResponsiveText
          fontSize={45}
          variant="h2"
          sx={{
            textAlign: "center",
            margin: "auto",
            color: "text.white",
            ...(titleProps.sx || {}),
          }}
        >
          {title ? (
            title
          ) : (
            <>
              <InlineSpan inherit>{t("An AI Writer Loved by")} </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  color: "#E910D3",
                }}
              >
                <TypeWriterWrapper
                  words={[
                    "Copywriters",
                    "Entrepreneurs",
                    "Marketers",
                    "Bloggers",
                    "Startups",
                  ]}
                  typeSpeed={0}
                  deleteSpeed={0}
                  delaySpeed={2000}
                  cursor={false}
                />
              </InlineSpan>
            </>
          )}
        </ResponsiveText>

        <ResponsiveText
          fontSize={18}
          sx={{
            color: "#D4DCE5",
            maxWidth: "500px",
            textAlign: "center",
            padding: "0 12px",
            margin: "12px auto auto auto",
          }}
        >
          {description
            ? description
            : t(
                "Don't just take our word for it. See what people find amazing about us."
              )}
        </ResponsiveText>
      </Box>
      <TestmonialList inReviewPage={inReviewPage} />
      {inReviewPage || isCn() ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HighlightedCallToAction
            route={serverRoutes.testimonials}
            rootProps={{
              sx: {
                maxWidth: "200px",
                margin: "12px auto",
              },
            }}
          >
            {t("See All Reviews")}
          </HighlightedCallToAction>
        </Box>
      )}
      {inReviewPage ? null : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "36px",
            marginTop: "36px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              columnGap: "48px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              },
              alignItems: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
              },
              rowGap: "36px",
            }}
          >
            <a
              rel="noopener noreferrer nofollow"
              href="https://www.producthunt.com/posts/junia-ai-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-junia&#0045;ai&#0045;2"
              target="_blank"
            >
              <Box
                component="img"
                width={150}
                height={54}
                alt="Junia AI is featured on ProductHunt.com - rated 4.8 stars out of 5 in reviews as a powerful AI writing tool for SEO content creation."
                sx={{
                  ...commonLogoStyle,
                  height: "54px",
                }}
                src={
                  "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=402268&theme=light"
                }
              />
            </a>
            <Box
              href="https://aitoptools.com/tool/junia/"
              target="_blank"
              title="Junia"
              component="a"
              sx={{
                ...hideOnMobile,
              }}
            >
              <Box
                component="img"
                alt="Junia AI is featured on aitoptools.com as a powerful AI blog writer - rated 4.7 stars out of 5 in reviews."
                src="https://aitoptools.com/emded-image.png"
                sx={{
                  ...commonLogoStyle,
                  height: "54px",
                }}
              />
            </Box>
            {/*
             <Image
              src={TopAITools}
              width={232}
              alt="Junia AI is featured on aitoptools.com - rated 4.7 stars out of 5 in reviews."
              height={74}
              style={{
                objectFit: "contain",
                width: "232px",
                height: "74px",
              }}
            />
            */}
            <a
              rel="noopener noreferrer nofollow"
              href="https://www.trustpilot.com/review/junia.ai"
              target="_blank"
            >
              <Box
                component={Image}
                src={PilotBadge}
                width={150}
                alt="Junia AI is featured on TrustPilot.com - rated 4.3 stars out of 5 in reviews with many positive comments about its AI SEO writing tools."
                height={74}
                sx={{
                  ...commonLogoStyle,
                  height: "74px",
                }}
              />
            </a>
            <Box
              component={Image}
              sx={{
                ...commonLogoStyle,
                height: "74px",
              }}
              src={MSLogo}
              alt="Junia AI is a partner of Microsoft for Startups."
              width={150}
              height={74}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              columnGap: "48px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
              },
              alignItems: "center",
              rowGap: "36px",
            }}
          >
            <Box
              component={Image}
              sx={{
                ...commonLogoStyle,
                ...hideOnMobile,
                height: "45px",
              }}
              alt="Junia AI is featured on theresanaiforthat.com as a powerful AI writing software for SEO content writing."
              src={AIForThatLogo}
            />
            <Box
              component={Image}
              sx={{
                ...commonLogoStyle,
                ...hideOnMobile,
                height: "45px",
              }}
              width={150}
              height={45}
              alt="Junia AI is featured on MSN.com as one of the fastest-growing AI writing tools, helping websites with SEO content writing."
              src={MSNLogo}
            />
            <Box
              component={Image}
              sx={{
                ...commonLogoStyle,
                height: "45px",
              }}
              width={150}
              height={45}
              alt="Junia AI is featured on Business Insider as a top-tier AI blog writing tool for 2024."
              src={BusinessInsiderLogo}
            />
            <Box
              component={Image}
              sx={{
                ...commonLogoStyle,
                ...hideOnMobile,
                height: "45px",
              }}
              width={150}
              height={45}
              alt="Junia AI is featured on SaaSHub as the best long-form AI writer."
              src={SaasHubLogo}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Testmonial;
