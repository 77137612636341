"use client";
import React from "react";

import type { EnrichedTweet } from "../utils";
import { useMounted } from "../hooks";
import s from "./tweet-info-created-at.module.css";
import { dayjs } from "client-server-shared/utils/dayjs";

export const TweetInfoCreatedAt = ({ tweet }: { tweet: EnrichedTweet }) => {
  const mounted = useMounted();
  // If the date is displayed immediately, it will produce a server/client mismatch because the date
  // format will change depending on the user's browser. If the format were to be simplified to
  // something like "MMM d, y", then you could use the server date.
  const createdAt =
    typeof window !== "undefined" && mounted
      ? new Date(tweet.created_at)
      : null;

  return !createdAt ? null : (
    <a
      className={s.root}
      href={tweet.url}
      target="_blank"
      rel="noopener noreferrer nofollow"
      aria-label={dayjs(createdAt).format("h:mm a · MMM D, YYYY")}
    >
      <time dateTime={createdAt.toISOString()}>
        {dayjs(createdAt.toISOString()).format("h:mm a · MMM D, YYYY")}
      </time>
    </a>
  );
};
