import React from "react";

import type { Tweet } from "../api/index";
import type { TwitterComponents } from "./types";
import { TweetContainer } from "./tweet-container";
import { TweetHeader } from "./tweet-header";
import { TweetInReplyTo } from "./tweet-in-reply-to";
import { TweetBody } from "./tweet-body";
import { TweetMedia } from "./tweet-media";
import { TweetInfo } from "./tweet-info";
import { TweetActions } from "./tweet-actions";
import { TweetReplies } from "./tweet-replies";
import { enrichTweet } from "../utils";
import { useMemo } from "react";

type Props = {
  tweet: Tweet;
  components?: Omit<TwitterComponents, "TweetNotFound">;
};

export const EmbeddedTweet = ({ tweet: t, components }: Props) => {
  // useMemo does nothing for RSC but it helps when the component is used in the client (e.g by SWR)
  const tweet = useMemo(() => enrichTweet(t), [t]);
  return (
    <TweetContainer>
      <TweetHeader tweet={tweet} components={components} />
      <TweetBody tweet={tweet} />
      {tweet.mediaDetails?.length ? (
        <TweetMedia tweet={tweet} components={components} />
      ) : null}
      <TweetInfo tweet={tweet} />
      <TweetActions tweet={tweet} />
    </TweetContainer>
  );
};
